<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <Toolbar @reloadClicked="emit('reloadClicked')" />
      </div>
      <div class="c-header-filter-container c-header-icon-container">
        <ActiveFilters v-model:filters="filtersComputed" />
        <PrimeButton class="c-circular-button" @click="emit('toggleFilterSidebarClicked')" data-testid="c-show-sidebar">
          <i class="pi pi-filter c-default-button c-circular-icon"></i>
          <span class="px-3">{{ showSidebar ? t("common.hide-filter") : t("common.show-filter") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import ActiveFilters from "./ActiveFilters.vue";
import Toolbar from "./Toolbar.vue";
import { DiscountSearchFilters } from "../model/search/discount/DiscountSearchFilters";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps<{
  showSidebar: boolean;
  filters: DiscountSearchFilters;
}>();

const emit = defineEmits<{
  (e: "reloadClicked"): void;
  (e: "toggleFilterSidebarClicked"): void;
  (e: "update:filters", value: DiscountSearchFilters): void;
}>();

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: DiscountSearchFilters) => {
    emit("update:filters", value);
  },
});
</script>
