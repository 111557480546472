<template>
  <div class="field-checkbox">
    <Checkbox :inputId="id" v-model="valueComputed" :binary="true" :data-testid="id" :disabled="disabled" />
    <label :for="id" :data-testid="`${id}-label`">
      {{ label }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  value: boolean;
  label: string;
  id: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:value", value: boolean): void;
}>();

const valueComputed = computed<boolean>({
  get: () => {
    return props.value;
  },
  set: (value: boolean) => {
    emit("update:value", value);
  },
});
</script>
