<template>
  <div class="c-layout-content c-layout-margin">
    <CustomToast />
    <PrimeVueLocaleHandler />
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="$route.fullPath" />
    </router-view>
  </div>
</template>

<script setup lang="ts">
import { PrimeVueLocaleHandler } from "@cumulus/locale";
import { onMounted } from "vue";
import { CustomToast } from "@cumulus/toast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useAuth } from "@cumulus/event-bus";

const { t } = useI18n();
const router = useRouter();

router.beforeEach((to, _, next) => {
  if (to.meta?.title) {
    document.title = t(to.meta?.title as string);
  }
  next();
});

//Only running this code if the app is standalone
if (import.meta.env.VITE_APP_STANDALONE === "true") {
  const { getUser, isAuthenticated } = useAuth();
  onMounted(async () => (document.body.className = (await getUser()).getBodyCssClass(isAuthenticated.value)));
}
</script>
