<template>
  <CumulusDateTimePicker
    v-model:dateTime="activeFromComputed"
    :inputClass="val.activeFrom.$error ? 'p-invalid' : ''"
    dataTestId="discount-active-from-date"
    :debounceTime="400"
    id="active-from"
    :label="t('discount.active-from')"
    :isFloatingLabel="true"
  />
  <div class="col-12">
    <small class="p-error" v-if="val.activeFrom.$error" data-testid="discount-valid-from-date-error">
      {{ val.activeFrom.$errors[0].$message }}
    </small>
    <small class="p-error" v-if="val.activeFrom.$error" data-testid="discount-valid-from-time-error">
      {{ val.activeFrom.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { required } from "@/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { CumulusDateTimePicker } from "@cumulus/components";

const props = defineProps<{
  activeFrom: string;
}>();

const emit = defineEmits<{
  (e: "update:activeFrom", value: string): void;
}>();

const { t } = useI18n();

const activeFromComputed = computed<string>({
  get: () => {
    return props.activeFrom ? props.activeFrom : "";
  },
  set: (value) => {
    if (value) {
      const time = activeFromTimeComputed.value?.length > 0 ? new Date(activeFromTimeComputed.value) : new Date();
      const date = new Date(value);
      date.setHours(time.getHours(), time.getMinutes(), 0);

      emit("update:activeFrom", date.toJSON());
    }
  },
});

const activeFromTimeComputed = computed<string>({
  get: () => {
    return props.activeFrom ? props.activeFrom : "";
  },
  set: (value) => {
    if (value) {
      const tempTime = value.split(":");
      const date = activeFromComputed.value?.length > 0 ? new Date(activeFromComputed.value) : new Date();
      date.setHours(parseInt(tempTime[0], 10), parseInt(tempTime[1], 10), 0);

      emit("update:activeFrom", date.toJSON());
    }
  },
});

const rules = {
  activeFrom: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
