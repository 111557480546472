<template>
  <DataTable
    :value="customers"
    stripedRows
    dataKey="id"
    ref="customerSearchListRef"
    class="c-compact-datatable"
    responsiveLayout="scroll"
    selectionMode="single"
    @row-select="onRowSelected"
    @row-dblclick="onRowDblClick"
    scrollHeight="400px"
    :loading="loading"
    :rowHover="true"
    data-testid="customer-search-result"
  >
    <Column field="customerNumber" :header="t('discount.customer.customer-number')">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-customer-search-result-customer-number-${index}`">{{ data[field] }}</span>
      </template>
    </Column>
    <Column field="name" :header="t('discount.customer.customer-name')">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-customer-search-result-name-${index}`">{{ data[field] }}</span>
      </template>
    </Column>

    <template #empty>
      {{ t("discount.customer.no-found") }}
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { DataTableRowDoubleClickEvent, DataTableRowSelectEvent } from "primevue/datatable";
import { SearchCustomer } from "../model/search/customer/SearchCustomer";

defineProps<{
  customers: SearchCustomer[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "customerSelected", value: SearchCustomer): void;
  (e: "removeProductClicked", value: SearchCustomer): void;
}>();

const { t } = useI18n();

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  emit("customerSelected", event.data as SearchCustomer);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  emit("customerSelected", event.data as SearchCustomer);
};
</script>
