<template>
  <h4 class="text-center">{{ t("discount.rule.label") }}</h4>
  <div
    v-for="(value, index) in discountRulesComputed"
    :key="index"
    class="flex justify-content-between relative align-items-center p-2 border-round-md"
    :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
  >
    <label
      class="font-light"
      :for="'discount-rule-' + value.name.toLowerCase()"
      :data-testid="'c-discount-rule-label-' + value.name.toLowerCase()"
      >{{ t(`discount.rule.${value.name.toLowerCase()}`) }}</label
    >
    <Checkbox
      v-model="selectedDiscountRulesComputed"
      :value="value.name"
      :inputId="'discount-rule-' + value.name.toLowerCase()"
      :data-testid="'c-discount-rule-checkbox-' + value.name.toLowerCase()"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { DiscountRule } from "../model/discount/DiscountRule";

const props = defineProps<{
  selectedDiscountRules: DiscountRule[];
}>();

const emit = defineEmits<{
  (event: "update:selectedDiscountRules", value: string[]): void;
}>();

const { t } = useI18n();

const selectedDiscountRulesComputed = computed<string[]>({
  get: () => {
    return props.selectedDiscountRules ? props.selectedDiscountRules : [];
  },
  set: (value: string[]) => {
    emit("update:selectedDiscountRules", value);
  },
});

const discountRulesComputed = computed(() => {
  const allStatuses = Object.values(DiscountRule);
  return allStatuses.map((rule, index) => {
    return {
      name: rule,
      index: index,
    };
  });
});
</script>

<style scoped lang="scss">
.c-list-row-bg {
  background-color: var(--list-row-bg);
}

.c-list-alternate-row-bg {
  background-color: var(--list-alternate-row-bg);
}

h4,
label {
  font-family: var(--font-family);
}
</style>
