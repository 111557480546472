<template>
  <div class="card">
    <ColumnOptionsMenu :label="t('discount.product.discount')" :itemsList="items"></ColumnOptionsMenu>

    <DataTable
      :value="discounts"
      dataKey="id"
      class="c-compact-datatable"
      :paginator="true"
      :lazy="true"
      :loading="loading"
      :rows="pageSize"
      :totalRecords="totalHits"
      :currentPageReportTemplate="
        t('discount.current-page-template', {
          first: '{first}',
          last: '{last}',
          totalRecords: '{totalRecords}',
        })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      ref="searchDiscountList"
      v-model:selection="selectedDiscount"
      :stripedRows="true"
      selectionMode="single"
      data-testid="discount-search-result"
      tabindex="0"
      :rowsPerPageOptions="[50, 100]"
      :sortOrder="sortOrder"
      :sortField="sortField"
      removableSort
      @row-select="onRowSelected"
      @row-dblclick="onRowDblClick"
      @sort="onSort"
      @page="onPage"
      @column-resize-end="onColumnResizeEnd"
      @column-reorder="onColumnReorder"
      :rowClass="addGlobalSearchClass"
      scrollable
      scrollHeight="75vh"
      :resizableColumns="true"
      columnResizeMode="fit"
      :reorderable-columns="true"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field, index }">
          <template v-if="col.field === DiscountLinesColumns.Registered">
            <span :data-testid="`c-discount-list-registered-${index}`">{{ d(data[field]) }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.RegisteredByEmployeeInitials">
            <span :data-testid="`c-discount-list-user-${index}`">{{ data[field] }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.Name">
            <span
              class="c-discount-name-text"
              :data-testid="`c-discount-list-name-${index}`"
              @click="onDiscountNameClicked(data)"
              >{{ data[field] }}</span
            >
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.Description">
            <span :data-testid="`c-discount-list-description-${index}`">{{ data[field] }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.DiscountType">
            <span :data-testid="`c-discount-list-type-${index}`">{{
              t(`discount.type.${data[field].toLowerCase()}`)
            }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.DiscountRule">
            <span :data-testid="`c-discount-list-rule-${index}`">{{
              t(`discount.rule.${data[field].toLowerCase()}`)
            }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.ActiveFrom">
            <span :data-testid="`c-discount-list-valid-from-${index}`">{{ d(data[field]) }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.ActiveTo">
            <span :data-testid="`c-discount-list-valid-to-${index}`">{{ d(data[field]) }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.Active">
            <Checkbox v-model="data[field]" disabled :binary="true" :data-testid="`c-discount-list-active-${index}`" />
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>
      <template #empty
        ><span data-testid="c-dicount-list-not-found">{{ t("discount.not-found") }}</span></template
      >
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SearchDiscount } from "../model/search/discount/SearchDiscount";
import { ref, nextTick, watch, computed } from "vue";

import {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";

import { DiscountLinesColumns } from "../model/search/discount/DiscountLinesColumns";

import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import { useAuth } from "@cumulus/event-bus";

const { getUser } = useAuth();

const { t, d } = useI18n();
const searchDiscountList = ref();
const selectedDiscount = ref<SearchDiscount>(new SearchDiscount());
const visible = ref(false);

const props = defineProps<{
  discounts: SearchDiscount[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
  employeeId: string;
}>();

const emit = defineEmits<{
  (e: "rowDblClicked", value: string): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "refreshList"): void;
}>();

watch([() => props.discounts, () => props.loading], ([discounts, loading]) => {
  if (loading === false && discounts.length > 0) {
    nextTick(() => {
      focusSearchResult(selectedDiscount.value.id);
    });
  }
});
const ordersReadyForPickingSearchColumns: DataTableColumn[] = [
  { field: "registered", header: t("discount.registered"), sortable: true },
  { field: "registeredByEmployeeInitials", header: t("discount.user"), sortable: true },
  {
    field: "name",
    header: t("discount.name"),
    sortable: true,
  },
  {
    field: "description",
    header: t("discount.description"),
    sortable: true,
  },
  {
    field: "discountType",
    header: t("discount.type.label"),
    sortable: true,
  },
  { field: "discountRule", header: t("discount.rule.label"), sortable: true },
  { field: "activeFrom", header: t("discount.valid-from"), sortable: true },
  { field: "activeTo", header: t("discount.valid-to"), sortable: true },
  { field: "active", header: t("discount.active"), sortable: true },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("discountSearch", ordersReadyForPickingSearchColumns, null, (await getUser()).getEmployee().id);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) =>
      col.field !== DiscountLinesColumns.Registered &&
      col.field !== DiscountLinesColumns.Name &&
      col.field !== DiscountLinesColumns.DiscountRule
  );
});

const items = [
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    command: () => {
      onClickDiscountRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const onClickDiscountRefresh = () => {
  emit("refreshList");
};

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? ordersReadyForPickingSearchColumns
    : ordersReadyForPickingSearchColumns.filter(
        (c) =>
          c.field === DiscountLinesColumns.Registered ||
          c.field === DiscountLinesColumns.Name ||
          c.field === DiscountLinesColumns.DiscountRule
      );
};

const addGlobalSearchClass = (data: SearchDiscount) => {
  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedDiscount.value?.id === data?.id },
  ];
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  selectedDiscount.value = event.data;
  emit("rowDblClicked", event.data.id);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  selectedDiscount.value = event.data;
  emit("rowDblClicked", event.data.id);
};
const focusSearchResult = (id: string) => {
  nextTick(() => {
    if (props.discounts.length > 0 && searchDiscountList.value.$el) {
      const element = id === "" ? null : searchDiscountList.value.$el.querySelector(`tr.c-sr-id-${id}`);
      if (element) {
        (element as HTMLElement).focus();
        return;
      }

      searchDiscountList.value.$el.querySelector("tbody tr:first-of-type").focus();
    }
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult(selectedDiscount.value.id);

    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult(selectedDiscount.value.id);
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onDiscountNameClicked = (discount: SearchDiscount) => {
  selectedDiscount.value = discount;
  emit("rowDblClicked", discount.id);
};
</script>
<style scoped lang="scss">
.c-discount-name-text:hover {
  text-decoration: underline;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
