<template>
  <DataTable
    :value="customerGroups"
    stripedRows
    dataKey="id"
    ref="customerGroupSearchListRef"
    class="c-compact-datatable"
    responsiveLayout="scroll"
    selectionMode="single"
    @row-select="onRowSelected"
    @row-dblclick="onRowDblClick"
    scrollHeight="400px"
    :loading="loading"
    :rowHover="true"
    data-testid="customer-group-search-result"
  >
    <Column field="name" :header="t('discount.customer-group.name')">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-customer-group-search-result-name-${index}`">{{ data[field] }}</span>
      </template>
    </Column>

    <template #empty>
      {{ t("discount.customer-group.no-found") }}
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { DataTableRowDoubleClickEvent, DataTableRowSelectEvent } from "primevue/datatable";
import { CustomerGroup } from "../model/customer-group/CustomerGroup";

defineProps<{
  customerGroups: CustomerGroup[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "customerGroupSelected", value: CustomerGroup): void;
}>();

const { t } = useI18n();

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  emit("customerGroupSelected", event.data as CustomerGroup);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  emit("customerGroupSelected", event.data as CustomerGroup);
};
</script>
