<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <div v-tooltip.bottom="saveButtonTooltip">
          <PrimeButton
            data-testid="header-save-button"
            class="c-circular-button mr-3"
            @click="emit('saveClicked')"
            :disabled="isSaving || !hasUpdates"
          >
            <ProgressSpinner v-if="isSaving" class="c-spinner" />
            <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-3">{{ t("common.save") }}</span>
          </PrimeButton>
        </div>
        <PrimeButton data-testid="header-cancel-button" class="c-circular-button mr-3" @click="emit('cancelClicked')">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton
          v-if="isEditing"
          class="c-circular-button mr-3"
          @click="onConfirmDelete"
          data-testid="header-delete-button"
          :disabled="isDeleting"
        >
          <ProgressSpinner v-if="isDeleting" class="c-spinner" />
          <i v-else class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.delete") }}</span>
        </PrimeButton>
        <ConfirmPopup data-testid="discount-delete-confirm"></ConfirmPopup>
        <PrimeButton
          data-testid="header-toggle-panels-button"
          class="c-circular-button"
          @click="emit('toggleAllPanelsClicked')"
        >
          <i class="pi pi-clone c-default-button c-circular-icon"></i>
          <span class="px-3">{{ collapsedPanels ? t("common.open-panels") : t("common.close-panels") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('dialogCancelBtnClicked')"
    @discardClicked="emit('dialogDiscardBtnClicked')"
    @saveClicked="emit('saveClicked')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { UnsavedChangesDialog } from "@cumulus/components";
import { computed } from "vue";

const props = defineProps<{
  isSaving: boolean;
  isDeleting?: boolean;
  isEditing: boolean;
  collapsedPanels: boolean;
  hasUpdates: boolean;
  unsavedChangesDialogVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "cancelClicked"): void;
  (e: "deleteClicked"): void;
  (e: "toggleAllPanelsClicked"): void;
  (e: "dialogCancelBtnClicked"): void;
  (e: "dialogDiscardBtnClicked"): void;
}>();

const { t } = useI18n();
const confirm = useConfirm();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("deleteClicked");
    },
  });
};

const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>

<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}

.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
