<template>
  <CumulusDateTimePicker
    v-model:dateTime="activeToComputed"
    :inputClass="val.activeTo.$error ? 'p-invalid' : ''"
    dataTestId="discount-active-to-date"
    :debounceTime="400"
    id="active-to"
    :label="t('discount.active-to')"
    :isFloatingLabel="true"
  />
  <div class="col-12">
    <small class="p-error" v-if="activeToDateErrorComputed" data-testid="discount-valid-to-date-error">
      {{ val.activeTo.$errors[0].$message }}
    </small>
    <small class="p-error" v-if="activeToTimeErrorComputed" data-testid="discount-valid-to-time-error">
      {{ val.activeTo.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { required } from "@/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { CumulusDateTimePicker } from "@cumulus/components";

const props = defineProps<{
  activeTo: string;
  activeFrom: string;
}>();

const emit = defineEmits<{
  (e: "update:activeTo", value: string): void;
}>();

const { t } = useI18n();

const activeToComputed = computed<string>({
  get: () => {
    return props.activeTo ? props.activeTo : "";
  },
  set: (value) => {
    if (value) {
      const time = activeToTimeComputed.value?.length > 0 ? new Date(activeToTimeComputed.value) : new Date();
      const date = new Date(value);
      date.setHours(time.getHours(), time.getMinutes(), 0);
      emit("update:activeTo", date.toJSON());
    }
  },
});

const activeToTimeComputed = computed<string>({
  get: () => {
    return props.activeTo ? props.activeTo : "";
  },
  set: (value) => {
    if (value) {
      const tempTime = value.split(":");
      const date = activeToComputed.value?.length > 0 ? new Date(activeToComputed.value) : new Date();
      date.setHours(parseInt(tempTime[0], 10), parseInt(tempTime[1], 10), 0);

      emit("update:activeTo", date.toJSON());
    }
  },
});

const activeToDateErrorComputed = computed<boolean>(() => {
  return (
    val.value.activeTo.$error &&
    val.value.activeTo.$errors.some((e) => {
      return e.$validator === "toDateMustBeLaterThanFromDate" || e.$validator === "required";
    })
  );
});

const activeToTimeErrorComputed = computed<boolean>(() => {
  return (
    val.value.activeTo.$error &&
    val.value.activeTo.$errors.some((e) => {
      return e.$validator === "toTimeMustBeLaterThanFromTime" || e.$validator === "required";
    })
  );
});

const isToDateLaterThanFromDate = (value: string) => {
  if (!props.activeFrom || props.activeFrom === "") {
    return true;
  }
  const activeFrom = new Date(props.activeFrom);
  const activeTo = new Date(value);

  if (activeFrom.toDateString() === activeTo.toDateString()) {
    return true;
  }
  return activeTo > activeFrom;
};

const isToTimeLaterThanFromTime = (value: string) => {
  const activeFrom = new Date(props.activeFrom);
  const activeTo = new Date(value);

  if (activeFrom.toDateString() === activeTo.toDateString()) {
    const activeFromMinutes = activeFrom.getHours() * 60 + activeFrom.getMinutes();
    const activeToMinutes = activeTo.getHours() * 60 + activeTo.getMinutes();
    return activeToMinutes > activeFromMinutes;
  }
  return true;
};

const rules = {
  activeTo: {
    required: required,
    toDateMustBeLaterThanFromDate: helpers.withMessage(
      t("validations.to-date-must-be-later-than-from-date"),
      (value: string) => {
        return isToDateLaterThanFromDate(value);
      }
    ),
    toTimeMustBeLaterThanFromTime: helpers.withMessage(
      t("validations.to-time-must-be-later-than-from-time"),
      (value: string) => {
        return isToTimeLaterThanFromTime(value);
      }
    ),
  },
};

const val = useVuelidate(rules, props);
</script>
